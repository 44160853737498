import React, { useEffect, useContext } from 'react';
import { ProductInfoContext } from 'utils/helpers/pdpContexts';
import { useMembershipProducts } from 'utils/hooks';
import { UserContext } from 'utils/hooks/useUserContext/context';
import { CTA, ImageType, ProductDataType } from 'utils/types';

import MoreTestsSection from '../../../components/PDP/MoreTestsSection';

export interface ContentSection {
  title?: string;
  headline?: string;
  subHeadline?: string;
  slug?: string;
  description?: {
    description: string;
  };
  desktopImages?: ImageType[];
  mobileImages?: ImageType[];
  modalButtonText?: string;
  modalTitle?: string;
  embeddedVideoLink?: string;
}

export interface MoreTestsContainerProps {
  content: {
    headline: string;
    cta: CTA;
    listOfContentSections: {
      slug: string;
    }[];
  };
}

export const MoreTestsContainer: React.FC<MoreTestsContainerProps> = ({
  content,
}) => {
  const { productsBySlug } = useContext(ProductInfoContext);
  const { userData } = useContext(UserContext);
  const productsWithMembership = useMembershipProducts(
    userData,
    productsBySlug,
  );

  // necessary for SSR https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [hasMounted, setHasMounted] = React.useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!content) {
    return null;
  }

  if (!hasMounted) {
    return null;
  }

  const { headline, cta, listOfContentSections } = content;

  const formattedListOfContent: ProductDataType[] = listOfContentSections.map(
    (item) => {
      const product = productsBySlug[item?.slug];

      return {
        displayName: product?.name,
        name: product?.name,
        slug: product?.slug,
        variantId: product?.variantId,
        isNew: product?.isNew,
        price: Number(product?.price),
        pdpImage: product?.boxImage,
        activeCredits: productsWithMembership[item.slug]?.activeCredits || 0,
        screener: product?.screener,
        disclaimer: product?.disclaimer,
        outOfStock: !!product?.outOfStock,
        onSale: product?.onSale,
        // Data below is not relevant for the more tests section
        category: '',
        description: '',
        productId: 0,
        markerList: {
          markers: [],
        },
        measures: '',
        sku: '',
        title: '',
      };
    },
  );

  return (
    <MoreTestsSection
      headline={headline}
      mainCta={cta}
      listOfContent={formattedListOfContent}
    />
  );
};

export default MoreTestsContainer;
