import {
  Container as RawContainer,
  size,
  Col as RawCol,
  mediaQueries,
  Button as RawButton,
  typography,
  colors,
} from '@everlywell/leaves';
import styled from 'styled-components';

export const InnerContent = styled.div`
  padding-top: 0;
`;

export const Img = styled.img`
  position: absolute;
  max-width: 72px;
  top: ${size.xl1}px;
  background-color: ${colors.teal2};

  ${mediaQueries.forTabletHorizontalUp} {
    display: none;
  }
`;

export const DesktopImg = styled.img`
  display: none;
  ${mediaQueries.forTabletHorizontalUp} {
    display: block;
    margin-top: ${size.xl1}px;
    max-width: 72px;
  }
`;

export const Headline = styled.h3`
  text-align: center;
  margin-bottom: ${size.xl2}px;
  ${typography.h2Text};

  ${mediaQueries.forTabletHorizontalUp} {
    ${typography.h3Text};
    margin-bottom: ${size.xl1}px;
    text-align: left;
  }
`;

export const DesktopHeadline = styled(Headline)`
`;

export const MobileShopCTAContainer = styled(RawContainer)`
  display: block;
  margin-top: ${size.xl2}px;
  ${mediaQueries.forTabletHorizontalUp} {
    display: none;
  }
`;

export const MobileCardsCol = styled(RawCol)`
  display: flex;
  flex-direction: column;

  ${mediaQueries.forTabletVerticalUp} {
    display: block;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const ContentContainer = styled.div`
  ${mediaQueries.forTabletHorizontalUp} {
    max-width: 192px;
    margin-right: ${size.xl1}px;
  }
`;

export const DesktopShopCTAButton = styled(RawButton)`
  display: none;
  ${mediaQueries.forTabletHorizontalUp} {
    display: block;
    width: 176px;
  }
`;

export const DesktopCardsContainer = styled.div`
  flex: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: ${size.xl2}px ${size.sm}px;
  margin: 0 auto;

  ${mediaQueries.forPhoneOnly} {
    grid-template-columns: repeat(1, 1fr);
  }

  ${mediaQueries.forTabletVerticalUp} {
    justify-content: center;
    gap: ${size.xl4}px ${size.xl1}px;
    grid-template-columns: repeat(auto-fill, 248px);
  }

  ${mediaQueries.forTabletHorizontalUp} {
    grid-template-rows: auto 0;
    gap: ${size.md}px ${size.xl1}px;
    overflow: hidden;
  }

  > div {
    a {
      height: 100%;
    }
  }
`;
