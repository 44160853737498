import { ExternalLinkType } from '@everlywell/leaves';
import { Link } from 'gatsby';
import React from 'react';

export const customGatsbyLink: ExternalLinkType = ({
  href,
  children,
  ...rest
}: {
  href: string;
  children: React.ReactNode;
}) => (
  <Link {...rest} to={href}>
    {children}
  </Link>
);
