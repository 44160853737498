import { colors, Card } from '@everlywell/leaves';
import { useCart } from 'components/Cart';
import DisclaimerModal, {
  DisclaimerModalProps,
} from 'components/DisclaimerModal';
import Pricing from 'components/Pricing';
import { GatsbyImage as Img } from 'gatsby-plugin-image';
import React, { ComponentType, useState } from 'react';
import { PRODUCT_SLUGS } from 'utils/constants/products';
import { Promo } from 'utils/helpers/activePromoHelpers';
import { customGatsbyLink } from 'utils/helpers/templateHelpers';
import { ProductDataType } from 'utils/types';

import { usePrePurchaseModalContext } from '../../contexts/PrePurchaseModal';
import {
  trackAddToCartButtonClick,
  trackCardClick,
} from './productCardHelpers';

const ADDED_TO_CART_STATE_DURATION_IN_MS = 1200;
const IMAGE_BACKGROUND_COLOR = colors.green1;

export type ProductCardWithScreenerProps = {
  product: ProductDataType;
  activePromo?: Promo;
  handleOnScreenerFlow: Function;
  // Injecting the disclaimer component as a dependency allow us
  // to simplify testing by passing in a mocked component
  CovidDisclaimerComponent?: ComponentType<DisclaimerModalProps>;
  cardBackgroundColor?: string;
  cardTrackingLabel: string;
  addToCartTrackingLabel: string;
};

export const ProductCardWithScreener = ({
  product,
  activePromo,
  handleOnScreenerFlow,
  CovidDisclaimerComponent = DisclaimerModal,
  cardBackgroundColor,
  cardTrackingLabel,
  addToCartTrackingLabel,
}: ProductCardWithScreenerProps): JSX.Element => {
  const [addedToCart, setAddedToCart] = useState<boolean>(false);

  const { addItemToCart } = useCart({
    addItemToCart: {
      onError: () => {
        setDisclaimerModalOpen(false);
        return true;
      },
      onSuccess: () => {
        setDisclaimerModalOpen(false);
        return true;
      },
    },
  });

  const isCovidDTC = product.slug === PRODUCT_SLUGS.COVID;
  const showAddToCartButton = !product.activeCredits;

  const prePurchaseModalContext = usePrePurchaseModalContext();

  // This disclaimer is specifically for Covid DTC
  // TODO: Leverage the new screener and disclaimer components for Covid
  const [disclaimerModalOpen, setDisclaimerModalOpen] =
    useState<boolean>(false);

  const handleAddToCartClick = () => {
    const { disclaimer, screener } = product;

    if (isCovidDTC) {
      setDisclaimerModalOpen(true);
    } else {
      // Shows screener if necessary, with addToCartProcess triggered afterwards,
      // or calls addToCartProcess directly if screener not needed
      handleOnScreenerFlow(
        screener,
        disclaimer,
        addToCartProcess,
        prePurchaseModalContext,
      );
    }
  };

  const addToCartProcess = () => {
    trackAddToCartButtonClick(product.slug, addToCartTrackingLabel);
    updateAddedToCartState();

    addItemToCart({
      variant_id: product.variantId,
      quantity: 1,
    });
  };

  const updateAddedToCartState = () => {
    setAddedToCart(true);
    setTimeout(() => setAddedToCart(false), ADDED_TO_CART_STATE_DURATION_IN_MS);
  };

  const getPricingOverride = (): React.ReactNode => (
    <Pricing
      price={product.price}
      promo={activePromo}
      productId={product.productId}
      promotionable={product.promotionable}
      memberPrice={product.memberPrice}
      subscribeSelected={false}
      memberHeroCopy={product.memberHeroCopy}
      isTextReduced={true}
      isOutOfStock={product.outOfStock}
      promoText={activePromo ? undefined : product.promoText}
    />
  );

  const productImageAlt = `${product.displayName} box image`;

  const getProductImage = (): React.ReactNode =>
    product.productCardImage ? (
      <Img
        image={product.productCardImage.gatsbyImageData}
        alt={productImageAlt}
        style={{
          width: '70%',
          margin: '0 auto',
          display: 'flex',
        }}
      />
    ) : (
      <Img
        image={product.pdpImage?.gatsbyImageData}
        alt={productImageAlt}
        style={{ width: '100%' }}
      />
    );

  return (
    <div onClick={() => trackCardClick(product.slug, cardTrackingLabel)}>
      <Card.Product
        image={getProductImage()}
        imageAlt={productImageAlt}
        productName={product.displayName}
        productUrl={`/products/${product.slug}/`}
        price={`$${(+product.price).toFixed(0)}`}
        promoPrice={product.memberPrice}
        promoText={product.promoText}
        reviewData={null}
        onAddToCartClick={handleAddToCartClick}
        showAddToCart={false}
        addedToCart={addedToCart}
        onRemoveFromCartClick={() => null}
        removedFromCart={false}
        isOutOfStock={product.outOfStock}
        isOnSale={product.onSale}
        isNew={product.isNew}
        showAddToCartButton={showAddToCartButton}
        description={product.shortDescription}
        imageBackgroundColor={cardBackgroundColor || IMAGE_BACKGROUND_COLOR}
        pricingOverride={getPricingOverride()}
        renderLinkAs={customGatsbyLink}
      />
      {isCovidDTC ? (
        <CovidDisclaimerComponent
          open={disclaimerModalOpen}
          openHandler={setDisclaimerModalOpen}
          productSku={product.sku}
          selectedQuantity={1}
          handleAddToCart={addToCartProcess}
        />
      ) : null}
    </div>
  );
};
