/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Container, Row, Col, Button, colors } from '@everlywell/leaves';
import loadable from '@loadable/component';
import { ProductCardWithScreener } from 'components/ProductCardWithScreener';
import { handleOnScreenerFlow } from 'components/ProductCardWithScreener/productCardHelpers';
import React from 'react';
import { Spinner } from 'styles/utils';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { CTA } from 'utils/types';
import { ProductDataType } from 'utils/types';

import * as S from './styles';

const SectionWrapper = loadable(() => import('components/SectionWrapper'), {
  fallback: <Spinner />,
});
declare global {
  interface Window {
    dataLayer: any;
    _vis_opt_queue: any;
  }
}

const IMAGE_BACKGROUND_COLOR = colors.white;

export interface MoreTestsSectionProps {
  headline: string;
  mainCta: CTA;
  listOfContent: ProductDataType[];
}

export const MoreTestsSection: React.FC<MoreTestsSectionProps> = ({
  headline,
  mainCta,
  listOfContent,
}) => {
  const handleShopAllClick = () => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.PDP_MORE_TESTS_CTA,
      },
    });
  };

  return (
    <>
      <SectionWrapper data-testid="popularTests">
        <S.InnerContent>
          <Container>
            <Row>
              <S.MobileCardsCol xs={12}>
                <S.ContentContainer>
                  <S.DesktopHeadline>{headline}</S.DesktopHeadline>
                  <S.DesktopShopCTAButton
                    appearance="secondary"
                    href={mainCta.url}
                    onClick={handleShopAllClick}
                  >
                    {mainCta.text}
                  </S.DesktopShopCTAButton>
                </S.ContentContainer>
                <S.DesktopCardsContainer>
                  {listOfContent.map((product) => (
                    <ProductCardWithScreener
                      product={product}
                      handleOnScreenerFlow={handleOnScreenerFlow}
                      key={product.slug}
                      cardBackgroundColor={IMAGE_BACKGROUND_COLOR}
                      cardTrackingLabel={
                        ANALYTICS.LABELS.PDP_POPULAR_PRODUCT_CARD
                      }
                      addToCartTrackingLabel={
                        ANALYTICS.LABELS.ADD_TO_CART_PDP_PRODUCT_CARD
                      }
                    />
                  ))}
                </S.DesktopCardsContainer>
              </S.MobileCardsCol>
            </Row>
          </Container>
          <S.MobileShopCTAContainer>
            <Row center="xs">
              <Col xs={12}>
                <Button
                  appearance="secondary"
                  href={mainCta.url}
                  onClick={handleShopAllClick}
                >
                  {mainCta.text}
                </Button>
              </Col>
            </Row>
          </S.MobileShopCTAContainer>
        </S.InnerContent>
      </SectionWrapper>
    </>
  );
};

export default MoreTestsSection;
