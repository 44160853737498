import { DisclaimerDataProps } from 'components/GeneralDisclaimer';
import { ScreenerDataProps } from 'components/PrePurchaseScreener';
import ewAnalytics from 'utils/analytics';
import { addToCartDataLayer } from 'utils/cartHelpers';
import { ANALYTICS } from 'utils/constants/analytics';
import { logError } from 'utils/helpers';
import { ProductDataType } from 'utils/types';

import {
  PrePurchaseModalActionsTypes,
  PrePurchaseModalContextType,
} from '../../contexts/PrePurchaseModal';

export const trackCardClick = (slug: string, label: string) => {
  ewAnalytics.track({
    event: ANALYTICS.EVENTS.CLICKED_BUTTON,
    data: {
      label,
      slug,
    },
  });
};

export const trackAddToCartButtonClick = (slug: string, label: string) => {
  ewAnalytics.track({
    event: ANALYTICS.EVENTS.CLICKED_BUTTON,
    data: {
      label,
      slug,
    },
  });
};

export const addToCartGTM = (
  variant: ProductDataType,
  productId: number,
  eventId: string,
) => {
  if (!window || !window.dataLayer || !variant) {
    return;
  }

  try {
    addToCartDataLayer(productId, variant.name, String(variant.price), eventId);
  } catch (err) {
    logError(
      'There was an error when attempting to add the addToCart event to GTM',
      {
        component: 'ProductDisplayGrid',
        function: 'addToCartGTM',
        productId,
        variant: variant.name,
        price: variant.price,
      },
    );
  }
};

const openScreenerFlow = (
  screener: ScreenerDataProps,
  disclaimer: DisclaimerDataProps,
  prePurchaseModalContext: PrePurchaseModalContextType,
  callback: () => void,
) => {
  prePurchaseModalContext?.dispatch({
    type: PrePurchaseModalActionsTypes.SetPrePurchaseScreener,
    payload: {
      isPrePurchaseScreenerOpen: true,
      prePurchaseScreenerData: screener,
      generalDisclaimerData: disclaimer,
      elegibleCallback: callback,
    },
  });
};

const openDisclaimerFlow = (
  disclaimer: any,
  prePurchaseModalContext: PrePurchaseModalContextType,
) => {
  prePurchaseModalContext?.dispatch({
    type: PrePurchaseModalActionsTypes.SetGeneralDisclaimer,
    payload: {
      isGeneralDisclaimerOpen: true,
      generalDisclaimerData: disclaimer,
    },
  });
};

export const handleOnScreenerFlow = (
  screener: any,
  disclaimer: any,
  callback: () => void,
  prePurchaseModalContext: PrePurchaseModalContextType,
) => {
  const hasScreener = Boolean(screener && disclaimer);
  const hasDisclaimer = Boolean(disclaimer);

  if (hasScreener) {
    openScreenerFlow(screener, disclaimer, prePurchaseModalContext, callback);
  } else if (hasDisclaimer) {
    openDisclaimerFlow(disclaimer, prePurchaseModalContext);
  } else {
    callback();
  }
};
